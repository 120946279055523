import React from "react";
import Retool from ".";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";

const AnalyticsDashboard = () => {
  const reportID = "f4f030d2-a00d-11ef-8095-6f79ad424ccb";
  const { id: BuildingId } = useParams();

  const embeddedData = {
    building_id: BuildingId,
  };

  return <Retool url={reportID} embeddedData={embeddedData} />;
};

export default observer(AnalyticsDashboard);
