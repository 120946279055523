import { useTranslation } from "react-i18next";
import useFeatureFlag from "./useFeatureFlag";
import { useRecoilValue } from "recoil";
import { auditsAtom } from "store/atoms/audits";
import { buildingStore } from "store/Building/BuildingStore";
import useCAOnlyFeature from "./useCAOnlyFeature";
import { useConnectionStatus } from "./useConnectionStatus";
import { UPCOMING_OPERATION_LIST } from "features/Building/consts.d";

const useBuildingTabOptions = () => {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();
  const isLcaEnabled = isFeatureVisible("lca_dashboard");
  const isSavingsPotentialEnabled = isFeatureVisible("savings_potential");
  const iscircularityEnabled = isFeatureVisible("circularity_dashboard");
  const isAuditOnlineEnabled = isFeatureVisible("audit_online");
  const isNetworkAvailable = useConnectionStatus();
  const { caOnlyFeature } = useCAOnlyFeature();

  const audits = useRecoilValue(auditsAtom);

  const isNotNewBuilding = () => {
    return (
      buildingStore.currentBuilding?.upcoming_operation !==
      UPCOMING_OPERATION_LIST[0].id
    );
  };

  const getCaOnlyOptions = () => {
    return [
      {
        item: t("buildingPassport.audits"),
        path: "audits",
      },
      ...(isNetworkAvailable && isAuditOnlineEnabled
        ? [
            {
              item: t("buildingPassport.auditsOnline"),
              path: "audits-online",
            },
          ]
        : []),
    ];
  };

  const options = [
    ...(caOnlyFeature
      ? getCaOnlyOptions()
      : [
          {
            item: t("buildingPassport.passport"),
            path: "passport",
          },

          {
            item: t("buildingInventory.inventory"),
            path: "inventory",
          },
          {
            item: t("buildingPassport.materialResources"),
            path: "material-resources",
          },
          ...(isNotNewBuilding()
            ? [
                {
                  item: t("commons.audit_material_sales"),
                  path: "audit-material-sales",
                },
              ]
            : []),
          {
            item: t("variants.title"),
            path: "variants",
          },
          ...(audits && audits.length > 0
            ? [
                {
                  item: t("buildingPassport.audits"),
                  path: "audits",
                },
              ]
            : []),
          ...(isNetworkAvailable &&
          isAuditOnlineEnabled &&
          audits &&
          audits.length > 0
            ? [
                {
                  item: t("buildingPassport.auditsOnline"),
                  path: "audits-online",
                },
              ]
            : []),
          ...(isLcaEnabled
            ? [
                {
                  item: t("dashboards.lca"),
                  path: "lca",
                },
              ]
            : []),
          ...(iscircularityEnabled
            ? [
                {
                  item: t("dashboards.circularity"),
                  path: "circularity",
                },
              ]
            : []),
          ...(buildingStore.currentBuilding?.exports_available
            ? [
                {
                  item: t("exports.title"),
                  path: "exports",
                },
              ]
            : []),

          ...(isSavingsPotentialEnabled
            ? [
                {
                  item: t("dashboards.savings_potential"),
                  path: "savings-potential",
                },
              ]
            : []),
          ...(isFeatureVisible("analytics_tab") &&
          buildingStore.buildingMappingStatus.hasReport
            ? [
                {
                  item: t("dashboards.analytics"),
                  path: "analytics",
                },
              ]
            : []),
        ]),
  ];

  return { options };
};

export default useBuildingTabOptions;
